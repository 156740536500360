<template>
  <page-template
    :img="{
      src: require('@/assets/home-1050x630.jpg'),
      lazy: require('@/assets/home-105x63.jpg'),
      height: 630,
    }"
  >
  <template v-slot:title>
    Witamy na stronie<br>Przedszkola Montessori Perełka
  </template>
    <h2 class="mb-5">Oferujemy Państwu:</h2>
    <ul>
      <li>tylko jedną, kameralną grupę prowadzoną przez dwóch wykwalifikowanych nauczycieli Montessori,</li>
      <li>program edukacji oparty na pedagogice dr M. Montessori,</li>
      <li>salę wyposażoną w oryginalne pomoce Montessori,</li>
      <li>atmosferę szacunku wobec dziecka i jego potrzeb,</li>
      <li>język angieski "Teddy Eddie", zajęcia umuzykalniające, zajęcia plastyczne - w ramach czesnego,</li>
      <li>zajęcia dodatkowe: taniec, gimnastyka, logopedia.</li>
    </ul>
  </page-template>
</template>
<script>
import PageTemplate from '../components/PageTemplate.vue';
export default {
  components: { PageTemplate }
}
</script>
